import React from "react"
import { Link , graphql} from "gatsby"
import WaypointView from '../components/waypointview'
import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import CSlist from "../components/cslist"
import csimg from "./../images/cciso.png"





const SecondPage = ({data}) => (
  <Layout pageclass = 'white'>
    <SEO title="Case Studies" keywords={['webdesign', 'application', 'development']} />
    <WaypointView always="always"><span className='acc'>case </span><span>STUDIES</span></WaypointView>
    <h3 className='headlinedesc'>web applications</h3>


    <div>


</div>


    <div className='services textflow'>
    <p className='col75'><em><strong>real-time ordering software</strong></em><br/>
    Chop Chop Club is a unique restaurant concept. Main dishes are served only at certain hours and only with limited quantity. Traditional order taking flow would face an overlapped orders problem. The objective was to develop a real-time application which would make that time-critical ordering process possible.  
    </p>

</div>
<div class='csimage'>
<img src={csimg} alt='case studies - real time ordering applications'/>
   
</div>
    <br /><br />
    <div className='services textflow'>
    <p className='col75'><em><strong>results</strong></em><br/>
    The project got bigger than expected.
We have added modules to order any a la carte dish, calculate revenue, configure daily menus and built touchscreen enabled ticket management software for chefs, to completely digitalize kitchen experience.<br/>In the end, we had multiple devices connected to the same platform: POS printers, tablets, laptops, and large, 4m long LED panels.<br/>We call it <strong>k-zen \'kai-zen'\</strong> - Kitchen Zen.
    </p>
    </div>
    <div className='services textflow'>
    <p className='col75'><em><strong>about technology</strong></em><br/>
NodeJs + Mongo + Meteor + Blaze
    </p>
    </div>
    
    <CSlist/>
  </Layout>
)

export default SecondPage


